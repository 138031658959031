import React from 'react';
import BaseFeedbacks from '@/ui/shared/components/Landing/Sections/Feedbacks';
import SectionHeader from '@/ui/shared/components/Landing/Sections/SectionHeader';
import Section from '@/ui/shared/components/Landing/Sections/Section';
import SectionContainer from '@/ui/shared/components/Landing/Sections/SectionContainer';

const Feedbacks = () => (
  <Section dataTest="feedbacksSection">
    <SectionContainer size="large">
      <SectionHeader
        title="Providing world-class fanatical 24x7 support."
        description="We offer unlimited, 24x7x365 U.S. based phone, email and live-chat support to all stakeholders utilizing our services. We don't ask for testimonials. Below is unsolicited feedback we’ve received from clients."
      />
      <BaseFeedbacks cardStyle="modern" />
    </SectionContainer>
  </Section>
);

export default Feedbacks;
